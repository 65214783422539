import React, { ReactElement, useState } from 'react';
import { 
  SharedLeaderGrant, Leader, StandardLeaderPermissions,
  ProfileNotesState, OrganizationLeader, LeaderPermission
} from '../../store';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import { EVENT_TYPE } from '../../constants';
import Luxon from '../Common/Luxon';
import { CabAvatar, CabButton, CabIcon } from '@CabComponents';
import { CabModal } from '@CabComponents/CabModal';
import { Box, ListItem, Typography, useTheme } from '@mui/material';
import { getIconSrc } from '../../utils/renderUtils';

interface ComponentProps {
  grant: SharedLeaderGrant;
  leader: Leader | OrganizationLeader;
  categories: ProfileNotesState['categories'];
  revoke: (grantId: number, leaderId: number) => void;
}

type Props = ComponentProps;

export const SharedLeaderItem = ({ grant, leader, categories, revoke }: Props): ReactElement => {
  const theme = useTheme();
  const [revokeAlertOpen, setRevokeAlertOpen] = useState(false);

  const handleOpenRevoke = (): void => {
    setRevokeAlertOpen(true);
  };

  const handleRevoke = (): void => {
    setRevokeAlertOpen(false);
    revoke(grant.id, leader.id);
    trackEvent(EVENT_TYPE.REVOKE_EXEC_PROFILE);
  };

  const basicPermissionDisplayMap: {[K in keyof StandardLeaderPermissions]: string} = {
    basic: 'Basic Info',
    airlines: 'Airlines',
    hotels: 'Hotels',
    personal: 'Family'
  };

  const editCategories: string[] = [];
  const viewCategories: string[] = [];
  Object.keys(basicPermissionDisplayMap).forEach(category => {
    const categoryIndex = category as keyof StandardLeaderPermissions;
    const categoryPermission = grant.permissions[categoryIndex];
    if (categoryPermission.edit) {
      editCategories.push(basicPermissionDisplayMap[categoryIndex]);
    } else if (categoryPermission.view) {
      viewCategories.push(basicPermissionDisplayMap[categoryIndex]);
    }
  });

  Object.values(categories).forEach(category => {
    if (category.id in grant.category_permissions) {
      const categoryPermission = grant.category_permissions[category.id];
      if (categoryPermission.edit) {
        editCategories.push(category.title);
      } else if (categoryPermission.view) {
        viewCategories.push(category.title);
      }
    }
  });


  const hasViewEditSchedulingPerms = Object.keys(grant.scheduling_permissions)
    .some(
      k => ['view', 'edit', 'create', 'delete'].includes(k) 
      && grant.scheduling_permissions[k as keyof LeaderPermission]
    );

  const hasOnlyAddToMeetingSchedulingPerms = !!grant.scheduling_permissions.add_to_meeting;

  return (
    <>
      <ListItem divider>
        <Box display="flex" flexDirection="column" gap={1} width="100%">
          <Box display="flex" justifyContent="space-between">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CabAvatar
                name={`${grant.to_user.first_name} ${grant.to_user.last_name}`}
                src={getIconSrc(grant.to_user.profile.pic_url)}
                color={theme.palette.primary.main}
                sx={{ marginRight: 1, width: '40px', height: '40px', fontSize: '16px' }}
              />
              <Typography>{grant.to_user.first_name + ' ' + grant.to_user.last_name}</Typography>
            </Box>
            <CabButton
              buttonType='text'
              color='error' 
              onClick={handleOpenRevoke}
              icon={<CabIcon Icon={IoCloseCircleOutline} alt='Revoke'/>}
            >
              Revoke
            </CabButton>
          </Box>
          <Box display="flex">
            <Box display="flex" flex={1} gap={1}>
              <Typography variant="subtitle2">Starting on: </Typography>
              <Luxon format="MM/dd/yyyy" date={grant.start_date} />
            </Box>
            {grant.end_date &&
              <Box display="flex" flex={1} gap={1}>
                <Typography variant="subtitle2">Ending on: </Typography>
                <Luxon format="MM/dd/yyyy" date={grant.end_date} />
              </Box>
            }
          </Box>
          {grant.is_admin ? (
            <Box style={{ display: 'flex' }}>
              <Typography>All Permissions Granted</Typography>
            </Box>
          ) : (
            <>
              <Box style={{ display: 'flex' }}>
                <Box display="flex" gap={1}>
                  <Typography variant="subtitle2">Scheduling:</Typography>
                  <Typography>
                    {hasViewEditSchedulingPerms 
                      ? 'View/Edit all meetings' 
                      : hasOnlyAddToMeetingSchedulingPerms 
                        ? 'Only add to meetings' 
                        : 'No'}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Box display="flex" gap={1} flex={1}>
                  <Typography variant="subtitle2" noWrap textOverflow="unset" overflow="visible">View-Only:</Typography>
                  <Typography>
                    {viewCategories.join(', ')}
                    {viewCategories.length < 1 && 'None'}
                  </Typography>
                </Box>
                <Box display="flex" gap={1} flex={1}>
                  <Typography variant="subtitle2">Edit:</Typography>
                  <Typography>
                    {editCategories.join(', ')}
                    {editCategories.length < 1 && 'None'}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </ListItem>

      <CabModal
        open={revokeAlertOpen}
        onClose={(): void => setRevokeAlertOpen(false)}
        title={'Revoke Access'}
        text={`Are you sure you want to revoke access to ${leader.first_name} ${leader.last_name}'s ` +
        `profile from ${grant.to_user.first_name} ${grant.to_user.last_name}?`}
        isAlert={true}
        noFullScreen={true}
        actionButtons={
          <>
            <CabButton buttonType='secondary' onClick={() => setRevokeAlertOpen(false)}>
              Cancel
            </CabButton>
            <CabButton onClick={handleRevoke}>
              Revoke
            </CabButton>
          </>
        }
      />
    </>
  );
};

export default SharedLeaderItem;